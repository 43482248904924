/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/

@import "accessibility";
@import "clearings";

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.required {
  color: #f00;
}

img {
  max-width: 100%;
  height: auto;
}
