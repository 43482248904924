.form--contact {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}

.form {
  position: relative;

  .required {
    color: #a94442;
  }

  &__group {
    position: relative;
    margin-bottom: 3rem;
  }

  &__input,
  &__label {
    height: auto;
    padding: .25em 0;
    font-size: 1.4em;
  }

  &__input {
    width: 100%;
    transition: .2s border ease-in-out;
    border: 0;
    border-bottom: 2px solid #aaa;
    border-radius: 0;
    background-color: transparent;
    color: #f2f2f2;

    .inverse & {
      color: #000;
    }

    &:focus {
      border-bottom: 2px solid #f2f2f2;
      outline: none;
      box-shadow: none;
    }
  }

  &__label {
    margin: 0;
    color: #aaa;
    pointer-events: none;

    &--hidden {
      opacity: 0;
    }

    &--placeholder {
      position: absolute;
      top: 5px;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translateY(1.5rem);
      transition: .2s color ease-in-out, .2s font-size ease-in-out, .2s padding ease-in-out, .2s transform ease-in-out;
    }
  }

  &__label--hidden,
  &__input--filled + &__label,
  &__input:focus + &__label {
    padding: 0;
    transform: translateY(0);
    color: #aaa;
    font-size: 1em;
    line-height: 1rem;
  }

  &__btn {
    padding: .5em 2em;
    transition: .2s background-color ease-in-out;
    border: 0;
    border-radius: 5em;
    background-color: #aaa;
    color: #000;
    font-family: $basicGrotesqueBold;
    font-size: 1.4em;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      background-color: #fff;
      text-decoration: none;
    }
  }

  &__spinner {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: #010101;
    color: #fff;
    /*background-image: url('/img/spinner.gif');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: 180px;
    pointer-events: none;

    &__content {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
      text-align: center;
    }

    &__content__text {
      display: block;
      margin-bottom: .5em;
      font-family: $basicGrotesqueBold;
      font-size: 1.2em;
      text-transform: uppercase;
    }

    &__content__emoji {
      display: block;
      padding: .1em;
      font-size: 1.4em;
    }

    &__content__emoji > span {
      display: inline-block;
      z-index: 1;
      padding: 2.5px;
      transition: .4s opacity ease-in-out;
      opacity: 0;
    }

    &.in {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }

    &.in &__content__emoji > span {
      opacity: 1;
    }

    $emojiCount: 7;
    @for $i from 1 to $emojiCount {
      &.in &__content__emoji > span:nth-of-type( #{$i} ) {
        transition-delay: $i + s;
      }
    }
  }

  &__message {
    border: 0;
    border-radius: 0;
    font-family: $basicGrotesqueBold;
    font-size: 1em;
    text-align: center;
  }
}
