.portfolio-panel {
  $borderWeight: 5px;
  display: block;
  position: relative;
  margin-bottom: 25px;
  border: 0;
  border-radius: 0;

  &__image {
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;

    img {
      display: block;
      margin-bottom: - $borderWeight;
    }
  }

  &__border {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &__bg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__bg:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .2);
    }

    &--left {
      top: 0;
      right: auto;
      bottom: 0;
      left: -$borderWeight;
      width: $borderWeight;
      transform: translateX(0) translateY($borderWeight / 2) rotateY(180deg);
    }

    &--left &__bg {
      transform: skewY(45deg);
      background-size: auto calc(100% + #{$borderWeight});
    }

    &--bottom {
      top: auto;
      right: 0;
      bottom: -$borderWeight;
      left: 0;
      height: $borderWeight;
      transform: translateX(-$borderWeight / 2);
    }

    &--bottom &__bg {
      transform: skewX(-45deg);
      background-position: 0 100%;
      background-size: 100% auto;
    }
  }
}
