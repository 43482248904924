// https://css-tricks.com/glitch-effect-text-images-svg/

$bgColor: #010101;
$textColor: #aaa;

.glitch {
  display: inline-block;
  position: relative;
  background: $bgColor;
  color: $textColor;

  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-1 2s infinite linear alternate-reverse;
    background: $bgColor;
    color: $textColor;
    text-shadow: -1px 0 #f00;
  }


  &:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    background: $bgColor;
    color: $textColor;
    text-shadow: 1px 0 #00f;
  }
}

@keyframes noise-anim-1 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i * (1 / $steps / 10))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}

@keyframes noise-anim-2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i * (1 / $steps / 10))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}
