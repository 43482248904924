.section-hello {
  overflow: hidden;

  h2 {
    overflow: hidden;
    transform: translateX(100%);
    transition: 1.5s transform ease-in-out,
                2s opacity ease-in-out;
    opacity: 0;
  }

  &.in h2 {
    transform: translateX(0);
    opacity: 1;
  }
}

.section-portfolio {

  .portfolio__heading {
    transform: translateX(-100%);
    transition: 1s transform ease-in-out,
                1s opacity ease-in-out;
    opacity: 0;
  }

  &.in .portfolio__heading {
    transform: translateY(0);
    opacity: 1;
  }

  .portfolio-items-container {
    transform: translateY(200px);
    transition: 1.5s transform ease-in-out 1s,
                3s opacity ease-in-out 1s;
    opacity: 0;
  }

  &.in .portfolio-items-container {
    transform: translateY(0);
    opacity: 1;
  }
}

.section-contact {
  p {
    transform: translateY(50px);
    transition: .75s transform ease-in-out .5s,
                1.5s opacity ease-in-out .5s;
    opacity: 0;
  }

  &.in p {
    transform: translateY(0);
    opacity: 1;
  }

  form,
  .btn--contact {
    transform: translateY(50px);
    transition: .5s transform ease-in-out 2s,
                1s opacity ease-in-out 2s;
    opacity: 0;
  }

  &.in form,
  &.in .btn--contact {
    transform: translateY(0);
    opacity: 1;
  }
}
