$font__main: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__heading: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;

@mixin font__base-size() {

  @media (max-width: $break__xs) {
    @include font-size(1.5);
  }

  @media (min-width: $break__xs) {
    @include font-size(1.7);
  }
}

$basicGrotesqueRegular: basis-grotesque-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$basicGrotesqueBold: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@font-face{
  font-family: basis-grotesque-regular;
  src: url("/fonts/basis-grotesque-regular.eot");
  src: url("/fonts/basis-grotesque-regular.eot?#iefix") format("embedded-opentype"),
       url("/fonts/basis-grotesque-regular.woff") format("woff"),
       url("/fonts/basis-grotesque-regular.woff2") format("woff2"),
       url("/fonts/basis-grotesque-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: basis-grotesque-bold;
  src: url("/fonts/basis-grotesque-bold.eot");
  src: url("/fonts/basis-grotesque-bold.eot?#iefix") format("embedded-opentype"),
       url("/fonts/basis-grotesque-bold.woff") format("woff"),
       url("/fonts/basis-grotesque-bold.woff2") format("woff2"),
       url("/fonts/basis-grotesque-bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: $basicGrotesqueRegular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $basicGrotesqueBold;
}
