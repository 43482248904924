.social-media {
  margin: 0;
  padding: 0;
  font-family: $basicGrotesqueBold;

  &__item {
    display: block;
  }

  &__link {
    color: #e2e2e2;
    font-size: 2em;
    text-transform: uppercase;

    &:active,
    &:focus,
    &:hover {

      // scss-lint:disable SelectorDepth, NestingDepth

      &.linkedin {
        color: #007bb5;
      }

      &.facebook {
        color: #3b5998;
      }

      &.instagram {
        color: #c32aa3;
      }

      &.bitbucket {
        color: #0445ac;
      }

      &.email {
        color: #fff;
      }
    }
  }

  &__icon {
    display: none;
  }
}
