/*
* Usage: https://smacss.com/book/type-module
**/

@import "glitch";
@import "social-media";
@import "portfolio-panel";
@import "portfolio-item";
@import "form";
@import "animations";

body {
  background-color: #0a0a0a;
}

.site-header {
  opacity: 0;

  &.fixed {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }

  .navbar {
    margin-bottom: 0;
    background-color: #fff;
  }

  .navbar-inverse {
    border: 0;
    background-color: transparent;
    background-image: none;
  }

  .navbar-right {
    opacity: 0;
  }

  a {
    color: #000;
  }
}

.site-footer {
  padding: 5rem 0;
  background-color: #111;

  &.fixed {
    position: fixed;
    z-index: -1;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.section-hello {
  position: relative;
  padding: 20rem 0;
  background-color: #010101;
  background-repeat: no-repeat;
  /*background-image: url("/img/bg-hello.jpg");*/
  background-position: center;
  background-size: cover;
  text-align: center;

  @include respond-to(xs) {
    padding: 25rem 0;
  }

  .section-content {
    position: relative;
    z-index: 1;
  }

  h2 {
    color: #aaa;
    font-size: 4em;

    @include respond-to(xs) {
      font-size: 4.4em;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0 , .3);
  }
}

.section-portfolio {
  padding: 2.5rem 0;
  overflow: hidden;
  background-color: #f2f2f2;

  @include respond-to(xs) {
    padding: 5rem 0 15rem;
  }

  .portfolio-items {
    margin-right: -5px;
    margin-left: -5px;

    @include respond-to(xs) {
      margin-right: -15px;
      margin-left: -15px;
      transform: rotate(-25deg);
    }

    > .portfolio-item {
      //scss-lint:disable NestingDepth, SelectorDepth
      padding-right: 5px;
      padding-left: 5px;

      @include respond-to(xs) {
        padding-right: -15px;
        padding-left: -15px;
      }

      .portfolio-panel {
        margin-bottom: 10px;

        @include respond-to(xs) {
          margin-bottom: 25px;
        }
      }
    }
  }

  .portfolio__heading {
    position: relative;
    z-index: 1;
    width: 400px;
    max-width: 100%;
    margin: 2em 0;
    font-family: $basicGrotesqueRegular;
    font-size: 2rem;

    @include respond-to(xs) {
      margin: 10rem 0 5rem;
    }
  }
}

.section-about {
  padding: 15rem 0;
  background-color: #010101;
  color: #f2f2f2;
}

.section-contact {
  padding: 15rem 0;
  transition: .2s background-color ease-in-out, .2s color ease-in-out;
  background-color: #010101;
  color: #e2e2e2;

  @include respond-to(xs) {
    margin-top: -15rem;
    padding-top: 20rem;
  }

  &.inverse {
    background-color: #f2f2f2;
    color: #010101;
  }

  .contact-text {
    margin-bottom: 3em;
    font-size: 1.6em;
    text-align: center;

    @include respond-to(md) {
      font-size: 1.8em;
    }
  }

  .form__btn {
    display: inline-block;
    border: 2px solid transparent;
    background-color: #e2e2e2;
    color: #010101;

    &:active,
    &:focus,
    &:hover {
      border: 2px solid #010101;
      background-color: #e2e2e2;
      box-shadow: 0 5px 30px rgba(0, 0, 0, .2);
      text-decoration: none;
    }
  }
}
