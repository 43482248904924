/*--------------------------------------------------------------*/
@font-face {
  font-family: basis-grotesque-regular;
  src: url("/fonts/basis-grotesque-regular.eot");
  src: url("/fonts/basis-grotesque-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/basis-grotesque-regular.woff") format("woff"), url("/fonts/basis-grotesque-regular.woff2") format("woff2"), url("/fonts/basis-grotesque-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: basis-grotesque-bold;
  src: url("/fonts/basis-grotesque-bold.eot");
  src: url("/fonts/basis-grotesque-bold.eot?#iefix") format("embedded-opentype"), url("/fonts/basis-grotesque-bold.woff") format("woff"), url("/fonts/basis-grotesque-bold.woff2") format("woff2"), url("/fonts/basis-grotesque-bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

body {
  font-family: basis-grotesque-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

/*--------------------------------------------------------------
1.0 Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-base
**/
/*--------------------------------------------------------------
3.0 Accessibility
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-module
**/
.glitch {
  display: inline-block;
  position: relative;
  background: #010101;
  color: #aaa; }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-1 2s infinite linear alternate-reverse;
    background: #010101;
    color: #aaa;
    text-shadow: -1px 0 #f00; }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    background: #010101;
    color: #aaa;
    text-shadow: 1px 0 #00f; }

@keyframes noise-anim-1 {
  0% {
    clip: rect(51px, 9999px, 76px, 0); }
  0.5% {
    clip: rect(13px, 9999px, 10px, 0); }
  1% {
    clip: rect(53px, 9999px, 82px, 0); }
  1.5% {
    clip: rect(48px, 9999px, 30px, 0); }
  2% {
    clip: rect(89px, 9999px, 57px, 0); }
  2.5% {
    clip: rect(66px, 9999px, 98px, 0); }
  3% {
    clip: rect(90px, 9999px, 72px, 0); }
  3.5% {
    clip: rect(38px, 9999px, 23px, 0); }
  4% {
    clip: rect(49px, 9999px, 33px, 0); }
  4.5% {
    clip: rect(2px, 9999px, 28px, 0); }
  5% {
    clip: rect(40px, 9999px, 47px, 0); }
  5.5% {
    clip: rect(35px, 9999px, 64px, 0); }
  6% {
    clip: rect(7px, 9999px, 16px, 0); }
  6.5% {
    clip: rect(30px, 9999px, 33px, 0); }
  7% {
    clip: rect(72px, 9999px, 90px, 0); }
  7.5% {
    clip: rect(83px, 9999px, 70px, 0); }
  8% {
    clip: rect(96px, 9999px, 75px, 0); }
  8.5% {
    clip: rect(75px, 9999px, 40px, 0); }
  9% {
    clip: rect(15px, 9999px, 13px, 0); }
  9.5% {
    clip: rect(24px, 9999px, 93px, 0); }
  10% {
    clip: rect(50px, 9999px, 70px, 0); } }

@keyframes noise-anim-2 {
  0% {
    clip: rect(78px, 9999px, 15px, 0); }
  0.5% {
    clip: rect(82px, 9999px, 41px, 0); }
  1% {
    clip: rect(12px, 9999px, 17px, 0); }
  1.5% {
    clip: rect(25px, 9999px, 12px, 0); }
  2% {
    clip: rect(8px, 9999px, 60px, 0); }
  2.5% {
    clip: rect(85px, 9999px, 55px, 0); }
  3% {
    clip: rect(78px, 9999px, 13px, 0); }
  3.5% {
    clip: rect(83px, 9999px, 60px, 0); }
  4% {
    clip: rect(47px, 9999px, 64px, 0); }
  4.5% {
    clip: rect(65px, 9999px, 25px, 0); }
  5% {
    clip: rect(91px, 9999px, 23px, 0); }
  5.5% {
    clip: rect(75px, 9999px, 41px, 0); }
  6% {
    clip: rect(32px, 9999px, 9px, 0); }
  6.5% {
    clip: rect(49px, 9999px, 57px, 0); }
  7% {
    clip: rect(81px, 9999px, 100px, 0); }
  7.5% {
    clip: rect(76px, 9999px, 66px, 0); }
  8% {
    clip: rect(97px, 9999px, 61px, 0); }
  8.5% {
    clip: rect(97px, 9999px, 96px, 0); }
  9% {
    clip: rect(42px, 9999px, 98px, 0); }
  9.5% {
    clip: rect(98px, 9999px, 50px, 0); }
  10% {
    clip: rect(29px, 9999px, 69px, 0); } }

.social-media {
  margin: 0;
  padding: 0;
  font-family: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .social-media__item {
    display: block; }
  .social-media__link {
    color: #e2e2e2;
    font-size: 2em;
    text-transform: uppercase; }
    .social-media__link:active.linkedin, .social-media__link:focus.linkedin, .social-media__link:hover.linkedin {
      color: #007bb5; }
    .social-media__link:active.facebook, .social-media__link:focus.facebook, .social-media__link:hover.facebook {
      color: #3b5998; }
    .social-media__link:active.instagram, .social-media__link:focus.instagram, .social-media__link:hover.instagram {
      color: #c32aa3; }
    .social-media__link:active.bitbucket, .social-media__link:focus.bitbucket, .social-media__link:hover.bitbucket {
      color: #0445ac; }
    .social-media__link:active.email, .social-media__link:focus.email, .social-media__link:hover.email {
      color: #fff; }
  .social-media__icon {
    display: none; }

.portfolio-panel {
  display: block;
  position: relative;
  margin-bottom: 25px;
  border: 0;
  border-radius: 0; }
  .portfolio-panel__image {
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden; }
    .portfolio-panel__image img {
      display: block;
      margin-bottom: -5px; }
  .portfolio-panel__border {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .portfolio-panel__border__bg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .portfolio-panel__border__bg:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2); }
    .portfolio-panel__border--left {
      top: 0;
      right: auto;
      bottom: 0;
      left: -5px;
      width: 5px;
      transform: translateX(0) translateY(2.5px) rotateY(180deg); }
    .portfolio-panel__border--left .portfolio-panel__border__bg {
      transform: skewY(45deg);
      background-size: auto calc(100% + 5px); }
    .portfolio-panel__border--bottom {
      top: auto;
      right: 0;
      bottom: -5px;
      left: 0;
      height: 5px;
      transform: translateX(-2.5px); }
    .portfolio-panel__border--bottom .portfolio-panel__border__bg {
      transform: skewX(-45deg);
      background-position: 0 100%;
      background-size: 100% auto; }

.portfolio-item__link {
  display: block;
  position: relative;
  transition: .2s transform ease-in-out, .2s opacity ease-in-out, .2s box-shadow ease-in-out; }
  @media (min-width: 768px) {
    .portfolio-item__link {
      transform: skewX(25deg); } }
  .portfolio-item__link:hover {
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.5); }
    @media (min-width: 768px) {
      .portfolio-item__link:hover {
        transform: skewX(25deg) translateX(15px) translateY(-15px); } }

.portfolio-item__image {
  display: block; }
  .portfolio-item__image .portfolio-panel__image__overlay {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .4s opacity ease-in-out;
    opacity: 0;
    background-color: #fff; }
  .portfolio-item__image .portfolio-panel__border__bg:after {
    transition: .4s background-color ease-in-out; }

.portfolio-item__link:hover .portfolio-item__image .portfolio-panel__image__overlay {
  opacity: .4; }

.portfolio-item__link:hover .portfolio-item__image .portfolio-panel__border__bg:after {
  background-color: rgba(255, 255, 255, 0.2); }

.portfolio-item__url {
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);
  color: #222;
  text-align: center; }
  @media (min-width: 768px) {
    .portfolio-item__url {
      transform: skewX(-25deg) translateX(-50%) translateY(-50%) rotate(25deg); } }

.portfolio-item__url-box {
  display: block;
  position: relative;
  z-index: 1;
  padding: .25em 1em;
  transform: translateY(15px);
  transition: .4s opacity ease-in-out, .4s transform ease-in-out;
  border: 1px solid #000;
  border-radius: 5rem;
  opacity: 0;
  background-color: #000;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.7);
  color: #fff; }
  .portfolio-item__url-box:before {
    content: "";
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: -2px;
    transform: translateX(-50%) translateY(50%) rotate(45deg);
    border: 5px solid transparent;
    border-right-color: #000;
    border-bottom-color: #000; }

.portfolio-item__link:hover .portfolio-item__url-box {
  transform: translateY(0);
  transition-delay: .4s;
  opacity: 1; }

.form--contact {
  width: 500px;
  max-width: 100%;
  margin: 0 auto; }

.form {
  position: relative; }
  .form .required {
    color: #a94442; }
  .form__group {
    position: relative;
    margin-bottom: 3rem; }
  .form__input, .form__label {
    height: auto;
    padding: .25em 0;
    font-size: 1.4em; }
  .form__input {
    width: 100%;
    transition: .2s border ease-in-out;
    border: 0;
    border-bottom: 2px solid #aaa;
    border-radius: 0;
    background-color: transparent;
    color: #f2f2f2; }
    .inverse .form__input {
      color: #000; }
    .form__input:focus {
      border-bottom: 2px solid #f2f2f2;
      outline: none;
      box-shadow: none; }
  .form__label {
    margin: 0;
    color: #aaa;
    pointer-events: none; }
    .form__label--hidden {
      opacity: 0; }
    .form__label--placeholder {
      position: absolute;
      top: 5px;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translateY(1.5rem);
      transition: .2s color ease-in-out, .2s font-size ease-in-out, .2s padding ease-in-out, .2s transform ease-in-out; }
  .form__label--hidden,
  .form__input--filled + .form__label,
  .form__input:focus + .form__label {
    padding: 0;
    transform: translateY(0);
    color: #aaa;
    font-size: 1em;
    line-height: 1rem; }
  .form__btn {
    padding: .5em 2em;
    transition: .2s background-color ease-in-out;
    border: 0;
    border-radius: 5em;
    background-color: #aaa;
    color: #000;
    font-family: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.4em;
    text-decoration: none; }
    .form__btn:active, .form__btn:focus, .form__btn:hover {
      background-color: #fff;
      text-decoration: none; }
  .form__spinner {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: #010101;
    color: #fff;
    /*background-image: url('/img/spinner.gif');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: 180px;
    pointer-events: none; }
    .form__spinner__content {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
      text-align: center; }
    .form__spinner__content__text {
      display: block;
      margin-bottom: .5em;
      font-family: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 1.2em;
      text-transform: uppercase; }
    .form__spinner__content__emoji {
      display: block;
      padding: .1em;
      font-size: 1.4em; }
    .form__spinner__content__emoji > span {
      display: inline-block;
      z-index: 1;
      padding: 2.5px;
      transition: .4s opacity ease-in-out;
      opacity: 0; }
    .form__spinner.in {
      display: block;
      opacity: 1;
      pointer-events: auto; }
    .form__spinner.in .form__spinner__content__emoji > span {
      opacity: 1; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(1) {
      transition-delay: 1s; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(2) {
      transition-delay: 2s; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(3) {
      transition-delay: 3s; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(4) {
      transition-delay: 4s; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(5) {
      transition-delay: 5s; }
    .form__spinner.in .form__spinner__content__emoji > span:nth-of-type(6) {
      transition-delay: 6s; }
  .form__message {
    border: 0;
    border-radius: 0;
    font-family: basis-grotesque-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
    text-align: center; }

.section-hello {
  overflow: hidden; }
  .section-hello h2 {
    overflow: hidden;
    transform: translateX(100%);
    transition: 1.5s transform ease-in-out, 2s opacity ease-in-out;
    opacity: 0; }
  .section-hello.in h2 {
    transform: translateX(0);
    opacity: 1; }

.section-portfolio .portfolio__heading {
  transform: translateX(-100%);
  transition: 1s transform ease-in-out, 1s opacity ease-in-out;
  opacity: 0; }

.section-portfolio.in .portfolio__heading {
  transform: translateY(0);
  opacity: 1; }

.section-portfolio .portfolio-items-container {
  transform: translateY(200px);
  transition: 1.5s transform ease-in-out 1s, 3s opacity ease-in-out 1s;
  opacity: 0; }

.section-portfolio.in .portfolio-items-container {
  transform: translateY(0);
  opacity: 1; }

.section-contact p {
  transform: translateY(50px);
  transition: .75s transform ease-in-out .5s, 1.5s opacity ease-in-out .5s;
  opacity: 0; }

.section-contact.in p {
  transform: translateY(0);
  opacity: 1; }

.section-contact form,
.section-contact .btn--contact {
  transform: translateY(50px);
  transition: .5s transform ease-in-out 2s, 1s opacity ease-in-out 2s;
  opacity: 0; }

.section-contact.in form,
.section-contact.in .btn--contact {
  transform: translateY(0);
  opacity: 1; }

body {
  background-color: #0a0a0a; }

.site-header {
  opacity: 0; }
  .site-header.fixed {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0; }
  .site-header .navbar {
    margin-bottom: 0;
    background-color: #fff; }
  .site-header .navbar-inverse {
    border: 0;
    background-color: transparent;
    background-image: none; }
  .site-header .navbar-right {
    opacity: 0; }
  .site-header a {
    color: #000; }

.site-footer {
  padding: 5rem 0;
  background-color: #111; }
  .site-footer.fixed {
    position: fixed;
    z-index: -1;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0; }

.section-hello {
  position: relative;
  padding: 20rem 0;
  background-color: #010101;
  background-repeat: no-repeat;
  /*background-image: url("/img/bg-hello.jpg");*/
  background-position: center;
  background-size: cover;
  text-align: center; }
  @media (min-width: 768px) {
    .section-hello {
      padding: 25rem 0; } }
  .section-hello .section-content {
    position: relative;
    z-index: 1; }
  .section-hello h2 {
    color: #aaa;
    font-size: 4em; }
    @media (min-width: 768px) {
      .section-hello h2 {
        font-size: 4.4em; } }
  .section-hello:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3); }

.section-portfolio {
  padding: 2.5rem 0;
  overflow: hidden;
  background-color: #f2f2f2; }
  @media (min-width: 768px) {
    .section-portfolio {
      padding: 5rem 0 15rem; } }
  .section-portfolio .portfolio-items {
    margin-right: -5px;
    margin-left: -5px; }
    @media (min-width: 768px) {
      .section-portfolio .portfolio-items {
        margin-right: -15px;
        margin-left: -15px;
        transform: rotate(-25deg); } }
    .section-portfolio .portfolio-items > .portfolio-item {
      padding-right: 5px;
      padding-left: 5px; }
      @media (min-width: 768px) {
        .section-portfolio .portfolio-items > .portfolio-item {
          padding-right: -15px;
          padding-left: -15px; } }
      .section-portfolio .portfolio-items > .portfolio-item .portfolio-panel {
        margin-bottom: 10px; }
        @media (min-width: 768px) {
          .section-portfolio .portfolio-items > .portfolio-item .portfolio-panel {
            margin-bottom: 25px; } }
  .section-portfolio .portfolio__heading {
    position: relative;
    z-index: 1;
    width: 400px;
    max-width: 100%;
    margin: 2em 0;
    font-family: basis-grotesque-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 2rem; }
    @media (min-width: 768px) {
      .section-portfolio .portfolio__heading {
        margin: 10rem 0 5rem; } }

.section-about {
  padding: 15rem 0;
  background-color: #010101;
  color: #f2f2f2; }

.section-contact {
  padding: 15rem 0;
  transition: .2s background-color ease-in-out, .2s color ease-in-out;
  background-color: #010101;
  color: #e2e2e2; }
  @media (min-width: 768px) {
    .section-contact {
      margin-top: -15rem;
      padding-top: 20rem; } }
  .section-contact.inverse {
    background-color: #f2f2f2;
    color: #010101; }
  .section-contact .contact-text {
    margin-bottom: 3em;
    font-size: 1.6em;
    text-align: center; }
    @media (min-width: 1200px) {
      .section-contact .contact-text {
        font-size: 1.8em; } }
  .section-contact .form__btn {
    display: inline-block;
    border: 2px solid transparent;
    background-color: #e2e2e2;
    color: #010101; }
    .section-contact .form__btn:active, .section-contact .form__btn:focus, .section-contact .form__btn:hover {
      border: 2px solid #010101;
      background-color: #e2e2e2;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
      text-decoration: none; }

/*--------------------------------------------------------------
4.0 Layout
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-layout
**/
/*--------------------------------------------------------------
5.0 Misc
--------------------------------------------------------------*/
/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/
/* Text meant only for screen readers. */
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    font-size: 0.875rem;
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 15px 23px 14px;
    clip: auto !important;
    border-radius: 3px;
    background-color: #f1f1f1;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    color: #293743;
    font-weight: bold;
    line-height: normal;
    text-decoration: none; }

.clear::before,
.clear::after {
  content: "";
  display: table; }

.clear::after {
  clear: both; }

.alignleft {
  float: left; }

.alignright {
  float: right; }

.required {
  color: #f00; }

img {
  max-width: 100%;
  height: auto; }

/*--------------------------------------------------------------
6.0 Shaaaaaaaaaaaaame
--------------------------------------------------------------*/
/*
* Usage: Anything you aren't proud of, or hope/plan to fix later. Hopefully empty, but useful for identifying pain points when it's not.
**/
/*--------------------------------------------------------------
7.0 Overriding Bootstrap Styles
--------------------------------------------------------------*/
