
.portfolio-item {
  &__link {
    display: block;
    position: relative;
    transition: .2s transform ease-in-out, .2s opacity ease-in-out, .2s box-shadow ease-in-out;

    @include respond-to(xs) {
      transform: skewX(25deg);
    }

    &:hover {
      box-shadow: 0 5px 75px rgba(0, 0, 0, .5);

      @include respond-to(xs) {
        transform: skewX(25deg) translateX(15px) translateY(-15px);
      }
    }
  }

  &__image {
    display: block;

    .portfolio-panel__image__overlay {
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: .4s opacity ease-in-out;
      opacity: 0;
      background-color: #fff;
    }

    .portfolio-panel__border__bg:after {
      transition: .4s background-color ease-in-out;
    }
  }

  &__link:hover &__image {

    .portfolio-panel__image__overlay {
      opacity: .4;
    }

    .portfolio-panel__border__bg:after {
      background-color: rgba(255, 255, 255, .2);
    }
  }

  &__url {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translateX(-50%) translateY(-50%);
    color: #222;
    text-align: center;

    @include respond-to(xs) {
      transform: skewX(-25deg) translateX(-50%) translateY(-50%) rotate(25deg);
    }
  }

  &__url-box {
    display: block;
    position: relative;
    z-index: 1;
    padding: .25em 1em;
    transform: translateY(15px);
    transition: .4s opacity ease-in-out, .4s transform ease-in-out;
    border: 1px solid #000;
    border-radius: 5rem;
    opacity: 0;
    background-color: #000;
    box-shadow: 0 0 50px rgba(0, 0, 0, .7);
    color: #fff;

    &:before {
      content: "";
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 50%;
      margin-top: -2px;
      transform: translateX(-50%) translateY(50%) rotate(45deg);
      border: 5px solid transparent;
      border-right-color: #000;
      border-bottom-color: #000;
    }
  }

  &__link:hover &__url-box {
    transform: translateY(0);
    transition-delay: .4s;
    opacity: 1;
  }
}
